import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { tryAutoLogin } from "src/features/signin/exports/state/flows";
import { useMount } from "src/utils/miniReactUse";
import useAutoLoginCredentials from "ui/hooks/useAutoLoginCredentials";

export const AutoLoginGate: FC = ({ children }) => {
  const dispatch = useDispatch();

  const { accountId, areCredentialsPresent, token } = useAutoLoginCredentials();

  useMount(() => {
    if (!areCredentialsPresent) {
      return;
    }

    dispatch(tryAutoLogin({ accountId, token }));
  });

  return areCredentialsPresent ? null : <>{children}</>;
};
