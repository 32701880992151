import { datadogLogs } from "@datadog/browser-logs";
import {
  getEnvironmentName,
  getSessionTokenLifeTimeCheckInterval,
} from "environment";
import { SECOND } from "src/constants";
import { DataDogErrorName } from "src/enums";
import {
  fetchSessionDetails,
  refreshSessionToken,
} from "src/features/signin/exports/api";
import { parseAutoLoginSearchParams } from "utils/parseAutoLoginSearchParams";

interface PerformSessionApiRequestsType {
  expirationTsSec: number;
  sessionDetailsKey: string;
}

const performSessionApiRequests = async ({
  expirationTsSec,
  sessionDetailsKey,
}: PerformSessionApiRequestsType) => {
  const lifeTimeCheckInterval = Number(getSessionTokenLifeTimeCheckInterval());

  if (!expirationTsSec) {
    return;
  }

  const isExpirationTimeLessThanInterval =
    expirationTsSec * SECOND - Date.now() <= lifeTimeCheckInterval;

  if (!isExpirationTimeLessThanInterval) {
    return;
  }

  try {
    await refreshSessionToken();
    const sessionDetailsResponse = await fetchSessionDetails();

    const preparedData = {
      data: JSON.stringify(sessionDetailsResponse),
    };

    localStorage.setItem(sessionDetailsKey, JSON.stringify(preparedData));
  } catch {
    datadogLogs.logger.error(DataDogErrorName.SESSION_TOKEN_REFRESH);
  }
};

const validateSessionDetails = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const { areCredentialsPresent } = parseAutoLoginSearchParams(searchParams);

  /*
   * In case the query parameters required for auto login are provided,
   * we want to avoid using any stored data, we want to prevent refreshing of
   * the session data and delegate control to AutoLoginGate
   */
  if (areCredentialsPresent) {
    return;
  }

  const environmentName = getEnvironmentName();
  const SESSION_DETAILS_LS_KEY = `persist:${environmentName}:sessionDetails`;

  const sessionDetailsString = localStorage.getItem(SESSION_DETAILS_LS_KEY);
  const sessionDetails = JSON.parse(String(sessionDetailsString));

  if (!sessionDetails?.data || sessionDetails.data === "null") {
    return;
  }

  try {
    const { expirationTsSec } = JSON.parse(sessionDetails.data);

    await performSessionApiRequests({
      expirationTsSec: Number(expirationTsSec),
      sessionDetailsKey: SESSION_DETAILS_LS_KEY,
    });
  } catch {
    datadogLogs.logger.error(DataDogErrorName.VALIDATE_SESSION_DETAILS);
  }
};

export default validateSessionDetails;
