import { Nullable } from "src/types/common";

enum AutoLoginSearchParam {
  ACCOUNT_ID = "accountId",
  ONBOARDING = "onboarding",
  TOKEN = "token",
}

export const AUTOLOGIN_QUERY_KEYS = [
  AutoLoginSearchParam.ACCOUNT_ID,
  AutoLoginSearchParam.ONBOARDING,
  AutoLoginSearchParam.TOKEN,
];

export const parseAutoLoginSearchParams = (
  searchParams: URLSearchParams
): {
  accountId: Nullable<string>;
  areCredentialsPresent: boolean;
  token: Nullable<string>;
} => {
  const accountId = searchParams.get(AutoLoginSearchParam.ACCOUNT_ID);
  const token = searchParams.get(AutoLoginSearchParam.TOKEN);

  const areCredentialsPresent = !!accountId && !!token;

  return { accountId, areCredentialsPresent, token };
};
