import { useState } from "react";
import { useMount } from "src/utils/miniReactUse";
import { allOptionKeys } from "ui/scenes/settings/contents/app/AppSettingsContent";
import { parseAutoLoginSearchParams } from "utils/parseAutoLoginSearchParams";

const KEY_TARGET = "target";

const REFRESH_PERIOD = 200;

const getOptionsForMobileSettings = (query) =>
  allOptionKeys.filter((option) => query.get(option) === "true");

const parseAutoLoginCredentials = (search) => {
  const searchParams = new URLSearchParams(search);
  const autoLoginSearchParams = parseAutoLoginSearchParams(searchParams);
  const target = searchParams.get(KEY_TARGET);
  const options = getOptionsForMobileSettings(searchParams);

  return { ...autoLoginSearchParams, target, options };
};

const useAutoLoginCredentials = () => {
  const [credentials, setCredentials] = useState(
    parseAutoLoginCredentials(window.location.search)
  );

  useMount(() => {
    if (!credentials.areCredentialsPresent) {
      return;
    }

    const intervalId = setInterval(() => {
      const newCredentials = parseAutoLoginCredentials(window.location.search);

      setCredentials(newCredentials);

      if (!newCredentials.areCredentialsPresent) {
        clearInterval(intervalId);
      }
    }, REFRESH_PERIOD);
  });

  return credentials;
};

export default useAutoLoginCredentials;
